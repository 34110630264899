import { getStringOrNull } from '@/utils/helpers'
import { capitalize } from 'lodash'

class Canvas {
  constructor(canvasId, height, width) {
    this.canvas = document.getElementById(canvasId);
    this.context = this.canvas.getContext("2d");
    this.shapes = [];

    this.zoomFactor = 1.0;
    this.translateX = 0;
    this.translateY = 0;

    this.canvas.height = height;
    this.canvas.width = width;

    this.currentShapeIndex = null;
    this.isDragging = false;

    this.startX = 0;
    this.startY = 0;

    this.dragOffsetX = 0;
    this.dragOffsetY = 0;

    this.isPreview = false;

    this.isEditable = false;
    this.isEditableText = false;
    this.currentEditableObjectId = 1;
    this.currentShapeFontSize = 16;
    this.currentShapeWidth = 0;
    this.currentShapeHeight = 0;

    this.isVisible = true;

    // this.isCurrentTextBold = false;

    this.minFontSize = 8;

    this.canvas.addEventListener("dragover", (event) => {
      event.preventDefault();
    });

    this.canvas.addEventListener("drop", this.handleDrop.bind(this));
    this.canvas.addEventListener("mousedown", this.handleMouseDown.bind(this));
    this.canvas.addEventListener("mouseup", this.handleMouseUp.bind(this));
    this.canvas.addEventListener("mousemove", this.handleMouseMove.bind(this));
    this.canvas.addEventListener("mouseout", this.handleMouseOut.bind(this));
  }

  getGrid() {
    let { context } = this;
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    let w = this.canvas.width;
    let h = this.canvas.height;

    context.lineWidth = 0.3;
    context.strokeStyle = "black";
    context.fillStyle = "black";

    for (let i = 1; i < w; i++) {
      context.beginPath();
      if (i % 10 === 0) {
        context.moveTo(i, 0);
        context.lineTo(i, h);
        context.moveTo(i, 0);
      }
      context.closePath();
      context.stroke();
    }

    for (let i = 1; i < h; i++) {
      context.beginPath();
      if (i % 10 === 0) {
        context.moveTo(0, i);
        context.lineTo(w, i);
        context.moveTo(0, i);
      }
      context.closePath();
      context.stroke();
    }
  }

  drawShapes() {
    // Drawing Shape
    const { context, shapes } = this;

    context.save();
    context.scale(this.zoomFactor, this.zoomFactor);
    context.translate(this.translateX, this.translateY);
    this.getGrid();

    for (let shape of shapes) {
      if (shape.type == "text") {
        context.font = `${shape.fontSize}px ${shape.fontName}`;
        shape.lines = this.wrapText(this.context, shape.content, shape.width);
        shape.fontSize = this.calculateMaxFontSize(
          shape.fontSize,
          shape.fontName,
          this.context,
          shape.lines,
          shape.width,
          shape.height,
          shape.content,
          shape.styles.bold,
          shape.styles.italic
        );

        // if (shape.fontSize < 8) {
        //   shape.width = this.prevShapeWidth;
        //   shape.height = this.prevShapeHeight;
        //   this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
        //   return;
        // }

        this.currentShapeFontSize = shape.fontSize;

        // const textMetrics = context.measureText(shape.content);
        // shape.width = textMetrics.width + 10;
        // const maxWidth = this.canvas.width * 0.8;
        // shape.height = shape.fontSize + 2;
        // let lines;
        // if (shape.width > maxWidth) {
        //   // Wrap the text and adjust the shape's height
        //   lines = this.wrapText(
        //     context,
        //     shape.content,
        //     shape.x,
        //     centerY,
        //     maxWidth,
        //     shape
        //   );
        //   shape.height = lines.length * (shape.fontSize + 4);
        //   shape.width = maxWidth;
        // }

        context.strokeStyle = `${this.isEditable && shape.id === this.currentEditableObjectId
          ? "red"
          : "black"
          }`;
        context.lineWidth = 2;

        context.strokeRect(shape.x, shape.y, shape.width, shape.height);
        context.fillStyle = "white";

        context.fillRect(shape.x, shape.y, shape.width, shape.height);
        context.textAlign = "center";
        context.textBaseline = "middle";

        context.fillStyle = "black";

        const centerX = shape.x + shape.width / 2;
        const centerY = shape.y + shape.height / 2;

        context.fillText(shape.title, centerX, centerY);

      } else if (shape.type == "image") {

        context.strokeStyle = "black";
        context.font = "16px Arial";

        context.lineWidth = 2;
        context.strokeStyle = `${this.isEditable && shape.id === this.currentEditableObjectId
          ? "red"
          : "black"
          }`;
        context.strokeRect(shape.x, shape.y, shape.width, shape.height);
        context.fillStyle = "white";

        context.fillRect(shape.x, shape.y, shape.width, shape.height);
        context.textAlign = "center";
        context.textBaseline = "middle";

        context.fillStyle = "black";

        const centerX = shape.x + shape.width / 2;
        const centerY = shape.y + shape.height / 2;

        context.fillText(shape.title, centerX, centerY);
      } else if (["json_text", "json"].includes(shape.type)) {

        context.strokeStyle = "black";
        context.font = "16px Arial";

        context.lineWidth = 2;
        context.strokeStyle = `${this.isEditable && shape.id === this.currentEditableObjectId
          ? "red"
          : "black"
          }`;

        context.strokeRect(shape.x, shape.y, shape.width, shape.height);
        context.fillStyle = "white";

        context.fillRect(shape.x, shape.y, shape.width, shape.height);
        context.textAlign = "center";
        context.textBaseline = "middle";

        context.fillStyle = "black";

        const centerX = shape.x + shape.width / 2;
        const centerY = shape.y + shape.height / 2;

        context.fillText(shape.title, centerX, centerY);
      }
    }
    context.restore();
  }

  wrapText(context, text, maxWidth) {
    text = getStringOrNull(text);
    if (!text) text = '';
    const words = text.split(" ");
    let line = "";
    const lines = [];
    // let lineHeight = 20; // Adjust as needed

    for (let word of words) {
      const testLine = line.length === 0 ? word : line + " " + word;
      const metrics = context.measureText(testLine);
      const testWidth = metrics.width;

      if (testWidth > maxWidth) {
        lines.push(line);
        line = word;
      } else {
        line = testLine;
      }
    }

    lines.push(line);

    // Return the total height occupied by the multiline text
    return lines;
  }

  async drawPreviewShapes() {
    // Drawing Preview
    const { context, shapes } = this;
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);

    context.fillStyle = "white";
    context.fillRect(0, 0, this.canvas.width, this.canvas.height);

    context.save();
    context.scale(this.zoomFactor, this.zoomFactor);
    context.translate(
      this.translateX / this.zoomFactor,
      this.translateY / this.zoomFactor
    );
    for (let shape of shapes) {

      if (!shape.content) {
        continue
      }

      if (shape.type == "text") {
        context.font = `${shape.styles.italic} ${shape.styles.bold} ${shape.fontSize}px ${shape.fontName}`;

        shape.fontSize = this.calculateMaxFontSize(
          shape.fontSize,
          shape.fontName,
          this.context,
          shape.lines,
          shape.width,
          shape.height,
          shape.content,
          shape.styles.bold,
          shape.styles.italic
        );
        shape.lines = this.wrapText(context, shape.content, shape.width);

        this.currentShapeFontSize = shape.fontSize;

        context.fillStyle = "white";
        context.fillRect(shape.x, shape.y, shape.width, shape.height);

        context.textAlign = "left"; // Align text to the left
        context.textBaseline = "middle"; // Align text to the top

        context.fillStyle = "black";

        let centerY = shape.y + shape.height / 2;
        if (shape.lines) {
          for (let i = 0; i < shape.lines.length; i++) {
            context.fillText(
              shape.lines[i],
              shape.x,
              shape.y + i * (shape.fontSize + 4) + 10
            );
          }
        } else {
          context.fillText(shape.content, shape.x, centerY);
        }
      }
      else if (shape.type == "image") {
        const imageObj = new Image();
        imageObj.crossOrigin = "anonymous";
        imageObj.src = `${shape.content}`;
        await new Promise((resolve) => {
          imageObj.onload = () => {
            resolve()
          }
        })

        // imageObj.onload = () => {
        const imageAspectRatio = imageObj.width / imageObj.height;

        // Calculate the aspect ratio of the destination rectangle
        const destAspectRatio = shape.width / shape.height;

        // Calculate the position and dimensions of the drawn image
        let drawX, drawY, drawWidth, drawHeight;

        if (imageAspectRatio > destAspectRatio) {
          // Image is wider, so fit its width to the destination width
          drawWidth = shape.width;
          drawHeight = shape.width / imageAspectRatio;
          drawX = shape.x;
          drawY = shape.y + (shape.height - drawHeight) / 2;
        } else {
          // Image is taller, so fit its height to the destination height
          drawHeight = shape.height;
          drawWidth = shape.height * imageAspectRatio;
          drawX = shape.x + (shape.width - drawWidth) / 2;
          drawY = shape.y;
        }

        // imageObj.setAttribute("crossorigin", "anonymous");

        context.drawImage(
          imageObj,
          // shape.x * this.zoomFactor,
          // shape.y * this.zoomFactor,
          // shape.width * this.zoomFactor,
          // shape.height * this.zoomFactor
          drawX * this.zoomFactor,
          drawY * this.zoomFactor,
          drawWidth * this.zoomFactor,
          drawHeight * this.zoomFactor
        );
        // };
      }
      else if (["json_text", "json"].includes(shape.type)) {

        const imageObj = new Image();

        const base64Data = await this.generateImageFromHTML(shape)
          .catch((error) => {
            console.error(error);
          });
        imageObj.src = base64Data; // Use the base64Data here

        await new Promise((resolve) => {
          imageObj.onload = () => {
            resolve()
          }
        })

        // imageObj.onload = () => {
        const imageAspectRatio = imageObj.width / imageObj.height;

        // Calculate the aspect ratio of the destination rectangle
        const destAspectRatio = shape.width / shape.height;

        // Calculate the position and dimensions of the drawn image
        let drawX, drawY, drawWidth, drawHeight;

        if (imageAspectRatio > destAspectRatio) {
          // Image is wider, so fit its width to the destination width
          drawWidth = shape.width;
          drawHeight = shape.width / imageAspectRatio;
          drawX = shape.x;
          drawY = shape.y + (shape.height - drawHeight) / 2;
        } else {
          // Image is taller, so fit its height to the destination height
          drawHeight = shape.height;
          drawWidth = shape.height * imageAspectRatio;
          drawX = shape.x + (shape.width - drawWidth) / 2;
          drawY = shape.y;
        }

        context.drawImage(
          imageObj,
          // shape.x * this.zoomFactor,
          // shape.y * this.zoomFactor,
          // shape.width * this.zoomFactor,
          // shape.height * this.zoomFactor
          drawX * this.zoomFactor,
          drawY * this.zoomFactor,
          drawWidth * this.zoomFactor,
          drawHeight * this.zoomFactor
        );
        // };
      }
    }
    context.restore();
  }

  clearCanvas() {
    this.shapes = [];
    this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  }

  isMouseOnShape(x, y, shape) {
    if (this.isPreview) {
      return;
    }
    const leastX = shape.x;
    const maxX = shape.x + shape.width;
    const leastY = shape.y;
    const maxY = shape.y + shape.height;

    return x > leastX && y > leastY && x < maxX && y < maxY;
  }

  handleMouseDown(event) {
    if (this.isPreview) {
      return;
    }
    event.preventDefault();

    const rect = this.canvas.getBoundingClientRect();
    const mouseX = (event.clientX - rect.left) / this.zoomFactor;
    const mouseY = (event.clientY - rect.top) / this.zoomFactor;

    this.startX = mouseX;
    this.startY = mouseY;

    this.isEditable = false;
    this.isEditableText = false;
    this.currentEditableObjectId = null;

    for (let [index, shape] of this.shapes.entries()) {
      if (this.isMouseOnShape(this.startX, this.startY, shape)) {
        this.shapes.splice(index, 1); // Remove from the current position
        this.shapes.push(shape); // Add to the end
        this.currentShapeIndex = this.shapes.length - 1; // Update the current index
        this.isDragging = true;
        this.isPreview ? this.drawPreviewShapes() : this.drawShapes();

        if (shape.type == "text") {
          this.isEditable = true;
          this.isEditableText = true;
          this.currentEditableObjectId = shape.id;
          this.currentShapeFontSize = shape.fontSize;
          this.currentShapeHeight = shape.height;
          this.currentShapeWidth = shape.width;
        }
        if (["json_text", "json", "image"].includes(shape.type)) {
          this.isEditable = true;
          this.currentEditableObjectId = shape.id;
          this.currentShapeHeight = shape.height;
          this.currentShapeWidth = shape.width;
        }
      }
      this.drawShapes();
    }
  }

  handleMouseMove(event) {
    if (this.isPreview) {
      return;
    }
    if (!this.isDragging) return;

    event.preventDefault();

    const rect = canvas.getBoundingClientRect();
    const mouseX = (event.clientX - rect.left) / this.zoomFactor;
    const mouseY = (event.clientY - rect.top) / this.zoomFactor;

    let dx = mouseX - this.startX;
    let dy = mouseY - this.startY;

    let current_shape = this.shapes[this.currentShapeIndex];
    current_shape.x += dx;
    current_shape.y += dy;

    this.startX = mouseX;
    this.startY = mouseY;
    this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  }

  handleMouseUp(event) {
    if (this.isPreview) {
      return;
    }
    this.isDragging = false;
  }

  handleMouseOut(event) {
    if (this.isPreview) {
      return;
    }
    if (this.isDragging) {
      this.isDragging = false;
    }
  }

  handleDrop(e) {
    if (this.isPreview) {
      return;
    }

    const data = JSON.parse(e.dataTransfer.getData("json/application"));

    const mouseX =
      (e.clientX -
        this.canvas.getBoundingClientRect().left -
        this.dragOffsetX) /
      this.zoomFactor;
    const mouseY =
      (e.clientY - this.canvas.getBoundingClientRect().top - this.dragOffsetY) /
      this.zoomFactor;

    let conditionalParams = {};

    const defaultFontSize = 16;
    const defaultFontName = "Arial";

    data.type == "text"
      ? (conditionalParams = {
        fontSize: defaultFontSize,
        fontName: defaultFontName,
        styles: {
          bold: "",
          italic: "",
          strike: "",
        },
      })
      : {};


    e.preventDefault();
    const newShape = {
      id: this.shapes.length,
      x: mouseX,
      y: mouseY,
      title: data.title,
      content: data.content,
      type: data.type,
      height: 50,
      width: 200,
      key: data.key,
      ...conditionalParams,
    };

    this.shapes.push(newShape);
    this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  }

  setFontSize(fs) {
    if (fs < this.minFontSize) {
      this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
      return;
    }
    this.shapes.map((shape) => {
      if (shape.id === this.currentEditableObjectId) {
        shape.fontSize = parseInt(fs);
        this.currentShapeFontSize = shape.fontSize;
      }
    });
    this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  }

  incrementFontSize() {
    this.shapes.map((shape) => {
      if (shape.id === this.currentEditableObjectId) {
        shape.fontSize++;
        this.currentShapeFontSize = shape.fontSize;
      }
    });
    this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  }
  
  decrementFontSize() {
    this.shapes.map((shape) => {
      if (shape.id === this.currentEditableObjectId) {
        if (shape.fontSize - 1 < this.minFontSize) {
          this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
          return;
        }
        shape.fontSize--;
        this.currentShapeFontSize = shape.fontSize;
      }
    });
    this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  }

  // incrementShapeWidth() {
  //   this.shapes.map((shape) => {
  //     if (shape.id === this.currentEditableTextId) {
  //       shape.width++;
  //       this.currentShapeWidth = shape.width;
  //     }
  //   });
  //   this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  // }

  // decrementShapeWidth() {
  //   this.shapes.map((shape) => {
  //     if (shape.id === this.currentEditableTextId) {
  //       shape.width--;
  //       this.currentShapeWidth = shape.width;
  //     }
  //   });
  //   this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  // }

  // incrementShapeHeight() {
  //   this.shapes.map((shape) => {
  //     if (shape.id === this.currentEditableTextId) {
  //       shape.height++;
  //       this.currentShapeHeight = shape.height;
  //     }
  //   });
  //   this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  // }

  // decrementShapeHeight() {
  //   this.shapes.map((shape) => {
  //     if (shape.id === this.currentEditableTextId) {
  //       shape.height--;
  //       this.currentShapeHeight = shape.height;
  //     }
  //   });
  //   this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  // }

  changeFont(fontName) {
    this.shapes.map((shape) => {
      if (shape.id === this.currentEditableObjectId) {
        shape.fontName = fontName;
      }
    });
    this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  }

  calculateMaxFontSize(
    fs,
    fn,
    context,
    lines,
    maxWidth,
    maxHeight,
    content,
    bold,
    italic
  ) {
    // Start with a reasonable font size
    let fontSize = fs;

    while (true) {
      context.font = `${italic} ${bold} ${fontSize}px ${fn}`;
      let new_lines = this.wrapText(context, content, maxWidth);
      let longestLine = this.getLongestLine(new_lines);
      const textMetrics = context.measureText(longestLine);
      if (
        textMetrics.width <= maxWidth &&
        new_lines.length * (fontSize + 4) <= maxHeight
      ) {
        // Text fits within both width and height, return the font size
        return fontSize;
      }
      // Reduce the font size and try again
      fontSize--;
    }
  }

  setShapeWidth(w) {
    this.shapes.map((shape) => {
      if (shape.id === this.currentEditableObjectId) {
        // if (w > this.canvas.width || w < 50) {
        //   this.currentShapeWidth = shape.width;
        //   this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
        //   return;
        // }

        // if (
        //   shape.type == "text" &&
        //   this.calculateMaxFontSize(
        //     shape.fontSize,
        //     shape.fontName,
        //     this.context,
        //     shape.lines,
        //     w,
        //     shape.height,
        //     shape.content,
        //     shape.styles.bold,
        //     shape.styles.italic
        //   ) < 8
        // ) {
        //   this.currentShapeWidth = shape.width;
        //   this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
        //   return;
        // }

        shape.width = parseInt(w);
        this.currentShapeWidth = shape.width;
      }
    });
    this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  }

  setShapeHeight(h) {
    this.shapes.map((shape) => {
      if (shape.id === this.currentEditableObjectId) {
        // if (h > this.canvas.height || h < 10) {
        //   this.currentShapeHeight = shape.height;
        //   this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
        //   return;
        // }
        // if (
        //   shape.type == "text" &&
        //   this.calculateMaxFontSize(
        //     shape.fontSize,
        //     shape.fontName,
        //     this.context,
        //     shape.lines,
        //     shape.width,
        //     h,
        //     shape.content,
        //     shape.styles.bold,
        //     shape.styles.italic
        //   ) < 8
        // ) {
        //   this.currentShapeHeight = shape.height;
        //   this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
        //   return;
        // }
        shape.height = parseInt(h);
        this.currentShapeHeight = shape.height;
      }
    });
    this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  }

  toggleBold() {
    this.shapes.map((shape) => {
      if (shape.id === this.currentEditableObjectId) {
        shape.styles.bold == "bold"
          ? (shape.styles.bold = "")
          : (shape.styles.bold = "bold");
      }
    });
    this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  }

  toggleItalic() {
    this.shapes.map((shape) => {
      if (shape.id === this.currentEditableObjectId) {
        shape.styles.italic == "italic"
          ? (shape.styles.italic = "")
          : (shape.styles.italic = "italic");
      }
    });
    this.isPreview ? this.drawPreviewShapes() : this.drawShapes();
  }

  getLongestLine(lines) {
    let longestLine = "";
    let maxLength = 0;

    for (let line of lines) {
      const lineLength = this.context.measureText(line).width;
      if (lineLength > maxLength) {
        maxLength = lineLength;
        longestLine = line;
      }
    }

    return longestLine;
  }

  generateImageFromHTML(shape) {

    const content = this.getHTMLfromArray(shape);


    const data =
      `<svg 
        xmlns='http://www.w3.org/2000/svg' 
        width='${content.width}' 
        height='${content.height + 6}'>` +
      "<style>" +
      "td {padding: 10px;}" +
      "</style>" +
      "<foreignObject width='100%' height='100%'>" +
      content.html +
      "</foreignObject>" +
      "</svg>";

    var DOMURL = self.URL || self.webkitURL || self;
    var img = new Image();
    img.crossOrigin = "anonymous";
    var svg = new Blob([data], {
      type: "image/svg+xml;charset=utf-8",
    });

    return new Promise((resolve) => {
      this.blobToBase64(svg).then((res) => {
        resolve(res); // Resolve the promise with the base64 data
      });
    });
    // this.blobToBase64(svg).then((res) => {
    //   // do what you wanna do
    //   base64Data = res;
    //   // this.isVisible = true;
    // });

    // return "data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSczMDAnIGhlaWdodD0nNTYnPjxzdHlsZT50ZCB7cGFkZGluZzogMTBweDt9PC9zdHlsZT48Zm9yZWlnbk9iamVjdCB3aWR0aD0nMTAwJScgaGVpZ2h0PScxMDAlJz48ZGl2IHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hodG1sIiBzdHlsZT0iZm9udC1zaXplOjEycHgiPjx0YWJsZSBib3JkZXI9IjEiIGNlbGxzcGFjaW5nPSIwIiBib3JkZXJjb2xvcj0iYmxhY2siIGhlaWdodD0iNTAiIHdpZHRoPSIzMDAiIHN0eWxlPSJ0ZXh0LWFsaWduOiBjZW50ZXI7Ij48dHI+PHRoPmxhbmd1YWdlPC90aD48dGg+dGl0bGU8L3RoPjx0aD50cmFpbGVyX2xpbms8L3RoPjwvdHI+PHRyPjx0ZD5HZXJtYW48L3RkPjx0ZD4oUilldm9sdXRpb248L3RkPjx0ZD4oUilldm9sdXRpb248L3RkPjwvdHI+PC90YWJsZT48L2Rpdj48L2ZvcmVpZ25PYmplY3Q+PC9zdmc+";

    // var url = URL.createObjectURL(svg);
    // img.onload = function () {
    //   DOMURL.revokeObjectURL(url);
    // };
    // img.src = url;

    // return img.src;
  }

  blobToBase64 = (blob) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve) => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  createSVGTable(data, width, height) {
    // Create an SVG element
    const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    svg.setAttribute("width", width);
    svg.setAttribute("height", height);
    // svg.style.border = "2px solid black";

    // Create a table header row
    const headerRow = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "text"
    );
    headerRow.setAttribute("x", "10");
    headerRow.setAttribute("y", "30");
    headerRow.setAttribute("font-size", "20");
    headerRow.setAttribute("fill", "black");
    svg.appendChild(headerRow);

    // Extract header column names from the first object in the array
    const headers = Object.keys(data[0]);

    // Create header cells
    headers.forEach((header, index) => {
      const cell = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "tspan"
      );
      cell.textContent = header;
      cell.setAttribute("x", 10 + index * 100);
      headerRow.appendChild(cell);
    });

    // Create table rows
    data.forEach((item, rowIndex) => {
      const row = document.createElementNS(
        "http://www.w3.org/2000/svg",
        "text"
      );
      row.setAttribute("x", "10");
      row.setAttribute("y", `${60 + rowIndex * 40}`);
      row.setAttribute("font-size", "16");
      row.setAttribute("fill", "black");
      svg.appendChild(row);

      // Create cells for each data item
      headers.forEach((header, index) => {
        const cell = document.createElementNS(
          "http://www.w3.org/2000/svg",
          "tspan"
        );
        cell.textContent = item[header];
        cell.setAttribute("x", 10 + index * 100);
        row.appendChild(cell);
      });
    });

    // Serialize the SVG to a string
    const serializer = new XMLSerializer();
    const svgString = serializer.serializeToString(svg);

    return svgString;
  }

  getHTMLfromArray(shape) {

    let array = shape.content

    // Get all unique keys from the array
    const allKeys = new Set();
    if (!Array.isArray(array)) { return; }
    array.forEach((obj) => {
      for (const key in obj) {
        allKeys.add(key);
      }
    });

    // Sort the keys alphabetically
    const sortedKeys = Array.from(allKeys).sort();

    let html =
      '<div xmlns="http://www.w3.org/1999/xhtml" style="font-size:12px">';

    // Set the width and height of the container element

    // let h = array.length * 50;
    // let w = Object.keys(array[0]).length * 100;

    let h = shape.height
    let w = shape.width

    html += `<table border="1" cellspacing="0" bordercolor="black" height="${h}" width="${w}" style="text-align: center;">`;

    // Create the header row with sorted keys
    html += "<tr>";
    sortedKeys.forEach((key) => {
      html += `<th>${capitalize(key).replace(/_/g, ' ')}</th>`;
    });
    html += "</tr>";

    // Create rows for each object
    array.forEach((obj) => {
      html += "<tr>";
      sortedKeys.forEach((key) => {
        html += `<td>${obj[key] || ""}</td>`; // Add an empty cell if the property is missing
      });
      html += "</tr>";
    });

    html += "</table></div>";

    // Create a hidden container element to measure the dimensions
    const container = document.createElement("div");
    container.style.position = "absolute";
    container.style.left = "-9999px";
    container.style.top = "-9999px";
    container.style.visibility = "hidden";
    document.body.appendChild(container);

    // Render the HTML content to the hidden container
    container.innerHTML = html;

    // Measure the dimensions
    const width = container.offsetWidth;
    const height = container.offsetHeight;

    // Remove the hidden container
    document.body.removeChild(container);

    return {
      html,
      width: w,
      height: h,
    };
  }
}

export default Canvas;

// URL of the html2canvas.js file
