<template>
  <b-card class="container" style="height: 200vh">
    <!-- Save Report Builder Format Modal -->
    <b-modal
      id="saveReportFormatFormId"
      ref="saveReportFormatForm"
      ok-title="Save"
      @ok.prevent="saveReportFormat"
    >
      <b-form>
        <b-form-group label="Format Name">
          <b-form-input v-model="reportFormatName" />
        </b-form-group>
      </b-form>
    </b-modal>

    <b-card class="container">
      <div class="row">
        <div class="col-sm">
          <b-form-group label="Report Type">
            <v-select
              placeholder="Type"
              label="name"
              :reduce="(i) => i.key"
              :options="objects"
              :value="key"
              @input="onObjectSelect"
            />
          </b-form-group>
        </div>
        <div class="col-sm">
          <b-form-group label="Select from Saved Format">
            <v-select
              :disabled="!key"
              label="name"
              placeholder="Format"
              :reduce="(i) => i.id"
              :options="reportFormats"
              :value="reportFormatId"
              @input="onReportFormatSelected"
            />
          </b-form-group>
        </div>
      </div>
    </b-card>

    <b-card class="container">
      <div class="row">
        <div class="col-sm">
          <b-form-group v-if="key" :label="`Select ${keyName}`">
            <v-select
              :placeholder="keyName"
              label="name"
              :reduce="(i) => i.id"
              :options="objectItemList"
              :value="object_item_id"
              @input="onObjectItemSelect"
              multiple
              :close-on-select="false"
            />
          </b-form-group>
        </div>
      </div>
    </b-card>

    <b-card>
      <b-row>
        <b-card style="width: 100%">
          <!-- Preview -->
          <b-row
            v-if="canvasHasShapes"
            class="d-flex justify-content-between"
            style="width: 100%"
          >
            <b-col cols="2">
              <button
                :disabled="!this.object_item_id.length"
                class="btn btn-primary"
                @click="downloadPDF"
              >
                Download PDF
              </button>
            </b-col>
            <b-col cols="2">
              <button class="btn btn-primary" @click="handleClear">
                Clear Canvas
              </button>
            </b-col>
            <b-col cols="2">
              <button class="btn btn-primary" @click="openSaveReportModal">
                Save Format
              </button>
            </b-col>
            <b-col cols="5">
              <BRow
                v-if="canvas.isEditable"
                class="d-flex justify-content-between floating-editor"
              >
                <div
                  class="d-flex align-items-center justify-content-center w-75"
                >
                  <BFormSelect
                    size="sm"
                    v-model="selectedFontName"
                    @change="canvas.changeFont(selectedFontName)"
                    :options="fontNameOptions"
                    class="mr-1"
                    v-if="canvas.isEditableText"
                  ></BFormSelect>

                  <button
                    class="btn"
                    @click="canvas.incrementFontSize()"
                    v-if="canvas.isEditableText"
                  >
                    <BIconPlus />
                  </button>

                  <BFormInput
                    v-if="canvas.isEditableText"
                    size="sm"
                    v-model="canvas.currentShapeFontSize"
                    style="width: 60px; text-align: center"
                    @change="canvas.setFontSize(canvas.currentShapeFontSize)"
                  ></BFormInput>
                  <button
                    class="btn"
                    @click="canvas.decrementFontSize()"
                    v-if="canvas.isEditableText"
                  >
                    <BIconDash />
                  </button>

                  <div class="ml-2 d-flex align-items-center">
                    <button
                      v-if="canvas.isEditableText"
                      class="btn btn-primary"
                      @click="handleToggleBold"
                    >
                      <BIconTypeBold />
                    </button>
                    <button
                      class="btn btn-primary"
                      @click="canvas.toggleItalic()"
                      v-if="canvas.isEditableText"
                    >
                      <BIconTypeItalic />
                    </button>
                    <div class="d-flex align-items-center mx-1">
                      W:
                      <BFormInput
                        size="sm"
                        v-model="canvas.currentShapeWidth"
                        style="width: 75px; text-align: center"
                        @change="canvas.setShapeWidth(canvas.currentShapeWidth)"
                        class="mr-1"
                      ></BFormInput>
                      H:
                      <BFormInput
                        size="sm"
                        v-model="canvas.currentShapeHeight"
                        style="width: 75px; text-align: center"
                        @change="
                          canvas.setShapeHeight(canvas.currentShapeHeight)
                        "
                      ></BFormInput>
                    </div>
                  </div>
                </div>

                <button class="btn btn-primary-outline" @click="closeEditor()">
                  X
                </button>
              </BRow>
            </b-col>
            <b-col class="d-flex align-items-center" cols="1">
              <b-form-checkbox
                :disabled="!this.object_item_id.length"
                v-model="canvas.isPreview"
                name="check-button"
                switch
              >
                Preview
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>
      </b-row>
      <b-row>
        <b-card
          v-if="
            canvasHasShapes && canvas.isPreview && this.object_item_id.length
          "
        >
          <button class="btn btn-primary mr-2" @click="handlePrevPDF()">
            {{ "<" }}
          </button>
          <button class="btn btn-primary" @click="handleNextPDF()">
            {{ ">" }}
          </button>
        </b-card>
      </b-row>
      <b-row>
        <b-col cols="9" class="mr-1">
          <div class="canvas-container" style="height: 11.69in; width: 8.27in">
            <canvas id="canvas" ref="canvas"></canvas>
          </div>
        </b-col>
        <b-col style="height: 60%">
          <b-form-group>
            <b-form-input placeholder="Search..." v-model="draggableSearch" />
          </b-form-group>
          <div class="elements-container">
            <!-- 
              Draggable items are initialized by getting all dom elements
              inside of elements-container class div
              hence using style diplay none for filtering items
             -->
            <fragment
              v-for="(draggableDetail, dd_index) in draggableDetails"
              :key="'dd_index' + dd_index"
            >
              <div
                draggable="true"
                class="border element"
                :id="draggableDetail.id"
                :style="
                  filterDraggableItem(draggableDetail) ? '' : 'display: none;'
                "
              >
                <h5>{{ draggableDetail.title }}</h5>
                <p v-if="draggableDetail.type === 'image'">
                  ({{ draggableDetail.width + " X " + draggableDetail.height }})
                </p>
              </div>
            </fragment>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-card>
</template>

<script>
import {
  BCard,
  BFormSelect,
  BFormSelectOption,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BIcon,
  BIconPlus,
  BIconDash,
  BIconTypeBold,
  BIconTypeItalic,
  BIconTypeStrikethrough,
  BForm,
} from "bootstrap-vue";
import draggable from "vuedraggable";
import * as ReportBuilderServices from "@/apiServices/ReportBuilderServices";
import Canvas from "./canvas2.js";
import vSelect from "vue-select";
import jspdf from "jspdf";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BForm,
    BCard,
    BFormSelect,
    BFormSelectOption,
    draggable,
    BFormCheckbox,
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BIcon,
    BIconPlus,
    BIconDash,
    BFormInput,
    BFormGroup,
    BIconTypeBold,
    BIconTypeItalic,
    BIconTypeStrikethrough,
    vSelect,
  },
  data() {
    return {
      visible: true,
      key: "",
      keyName: "",
      selectedObject: {},
      isBold: false,
      currentPDFList: [],
      currentPDFIndex: 0,
      objectItemList: [],
      object_item_id: [],
      objects: [],
      objectSchema: [],
      selectedFontName: "Arial",
      fontNameOptions: [
        {
          value: "Arial",
          text: "Arial",
        },
        {
          value: "Times New Roman",
          text: "Times New Roman",
        },
        {
          value: "Monospace",
          text: "monospace",
        },
      ],
      showEditMode: false,
      checked: true,
      canvas: {},
      draggableSearch: "",
      draggableDetails: [
        // {
        //   id: 3,
        //   title: "Movie Image",
        //   content:
        //     "https://www.dreamworks.com/storage/cms-uploads/httyd-tv-special-poster.jpg",
        //   type: "image",
        //   height: 210,
        //   width: 140,
        // },
      ],

      reportFormatName: "",
      reportFormatId: "",
      reportFormats: [],
    };
  },
  computed: {
    canvasHasShapes() {
      return this.canvas && this.canvas.shapes && this.canvas.shapes.length;
    },
  },
  methods: {
    filterDraggableItem(i) {
      let search = this.draggableSearch;
      if (search && typeof search === "string") {
        search = search.toLowerCase();
        return i.title.toLowerCase().includes(search);
      }
      return true;
    },

    async onReportFormatSelected(id) {
      this.reportFormatId = id;
      if (!id) {
        this.reportFormatName = "";
        return;
      }
      try {
        const res = await ReportBuilderServices.GetSingleFormats(id);
        if (res.data.data) {
          this.reportFormatId = id;
          this.reportFormatName = res.data.data.name;
          this.canvas.shapes = JSON.parse(res.data.data.format_text);
          this.canvas.drawShapes();
        }
      } catch (error) {
        console.error(`Error in onReportFormatSelected `, error);
      }
    },

    async getReportFormats(key) {
      try {
        const res = await ReportBuilderServices.GetAllFormats({
          type: "PDF",
          key: key,
        });
        if (res.data.status) {
          this.reportFormats = res.data.data;
        }
      } catch (error) {
        console.error(`Error in getReportFormats`, error);
      }
    },

    async saveReportFormat() {
      try {
        const res = await ReportBuilderServices.SaveFormat({
          id: this.reportFormatId,
          type: "PDF",
          name: this.reportFormatName,
          key: this.key,
          format_text: JSON.stringify(this.canvas.shapes),
        });
        if (res.data.status) {
          this.reportFormatId = res.data.data;
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: res.data.message || "Success",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.getReportFormats(this.key);
        }
      } catch (error) {
        console.error(`Error in saveReportFormat `, error);
      }
      this.$refs["saveReportFormatForm"].hide();
    },

    openSaveReportModal() {
      this.$refs["saveReportFormatForm"].show();
    },

    handleToggleBold() {
      this.canvas.toggleBold();
    },

    handleFontSizeChange() {
      this.handlePreview();
    },

    handleClear() {
      this.canvas.clearCanvas();
    },

    async handlePreview() {
      if (this.canvas.isPreview) {
        await this.getPreviewDetails();
        await this.canvas.drawPreviewShapes();
      } else {
        await this.canvas.drawShapes();
      }
    },

    createCanvasInstance() {
      const canvasElement = document.getElementById("canvas");

      if (!canvasElement) {
        console.error("Canvas element not found.");
        return;
      }

      this.canvas = new Canvas(
        "canvas",
        this.getCanvasDimentions().height,
        this.getCanvasDimentions().width
      );
    },

    getCanvasDimentions() {
      const canvasElement = document.getElementById("canvas");
      return {
        height: canvasElement.parentElement.clientHeight,
        width: canvasElement.parentElement.clientWidth,
      };
    },

    initializeDraggableElements() {
      this.$nextTick(() => {
        const allElements = Array.from(
          document.querySelector(".elements-container").children
        );
        allElements.forEach((element) => {
          element.addEventListener("dragstart", (e) => {
            this.canvas.dragOffsetX =
              e.clientX - e.target.getBoundingClientRect().left;
            this.canvas.dragOffsetY =
              e.clientY - e.target.getBoundingClientRect().top;

            let elementData = this.draggableDetails.find(
              (detail) => detail.id === parseInt(element.id)
            );

            let data = {
              title: elementData.title,
              content: elementData.content,
              type: elementData.type,
              height: elementData.height,
              width: elementData.width,
              key: elementData.key,
            };

            e.dataTransfer.setData("json/application", JSON.stringify(data));
          });
        });
      });
    },

    confirmDelete(index, title) {
      this.$bvModal
        .msgBoxConfirm(`Would You Like to Delete '${title}'`, {
          okVariant: "danger",
        })
        .then((value) => {
          if (value) {
            this.canvas.shapes.splice(index, 1);
            this.canvas.isEditable = false;
            this.canvas.isPreview
              ? this.canvas.drawPreviewShapes()
              : this.canvas.drawShapes();
          }
        })
        .catch((err) => {
          console.error("Could Not Delete Item. ", err);
        });
    },

    async handleNextPDF() {
      if (this.currentPDFIndex == this.currentPDFList.length - 1) return;
      this.currentPDFIndex += 1;
      await this.setPreviewData();
      await this.canvas.drawPreviewShapes();
    },

    async handlePrevPDF() {
      if (this.currentPDFIndex == 0) return;
      this.currentPDFIndex -= 1;
      await this.setPreviewData();
      await this.canvas.drawPreviewShapes();
    },

    closeEditor() {
      this.canvas.isEditable = false;
      this.currentEditableTextId = null;
      this.canvas.isPreview
        ? this.canvas.drawPreviewShapes()
        : this.canvas.drawShapes();
    },

    resetAll() {
      this.key = "";
      this.keyName = "";
      this.objectItemList = [];
      this.object_item_id = [];
      this.draggableDetails = [];
      this.draggableSearch = "";
      this.reportFormatId = "";
      this.reportFormatName = "";
      this.reportFormats = [];
      this.canvas.isPreview = false;
      this.closeEditor();
      this.canvas.clearCanvas();
    },

    onObjectSelect(key) {
      this.resetAll();
      if (!key) return;
      this.key = key;
      this.keyName = this.objects.find((i) => i.key === key).name;
      this.getObjectSchema(key);
      this.getObjectItemList(key);
      this.getReportFormats(key);
    },

    onObjectItemSelect(input) {
      this.object_item_id = input;
      this.getPreviewDetails();
    },

    async getObjectItemList(key) {
      const res = await ReportBuilderServices.GetObjectList(key);
      this.objectItemList = res.data.data;
    },

    async getObjectSchema(key) {
      try {
        const res = await ReportBuilderServices.GetObjectsSchema(key);
        this.objectSchema = res.data.data;
        this.draggableDetails = [];

        for (let [index, object] of this.objectSchema.entries()) {
          this.draggableDetails.push({
            id: index + 6,
            title: object.name,
            type: object.type,
            content: "",
            height: 50,
            width: 300,
            key: object.key,
          });
        }

        this.initializeDraggableElements();
      } catch (error) {
        console.error(`Error in getObjectSchema`, error);
      }
    },

    async getObjects() {
      try {
        const res = await ReportBuilderServices.GetObjects();
        this.objects = res.data.data;
      } catch (error) {
        console.error(`Error in getObjects `, error);
      }
    },

    async downloadPDF() {
      if (!this.canvas.isPreview) {
        this.canvas.isPreview = true;
        await this.getPreviewDetails();
      }

      var pdf = new jspdf();
      for (let i = 0; i < this.currentPDFList.length; i++) {
        this.currentPDFIndex = i;
        await this.setPreviewData();

        await this.nextTickSync();
        const canvasElement = document.getElementById("canvas");
        const imgData = canvasElement.toDataURL("image/jpeg", 1.0);
        pdf.addImage(imgData, "jpeg", 0, 0);
        if (i !== this.currentPDFList.length - 1) {
          pdf.addPage();
        }
      }

      pdf.save("download.pdf");
    },

    nextTickSync() {
      return new Promise((resolve) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },

    async getPreviewDetails() {
      if (!this.canvasHasShapes) {
        return;
      }

      const res = await ReportBuilderServices.GetReportPdf({
        key: this.key,
        columns: this.canvas.shapes.map((shape) => ({
          key: shape.key,
          name: shape.title,
        })),
        object_item_id: this.object_item_id,
      });

      this.currentPDFList = res.data.data;
      this.setPreviewData();
    },

    async setPreviewData() {
      const detail = this.currentPDFList[this.currentPDFIndex];
      for (let detailKey in detail) {
        for (let cshape of this.canvas.shapes) {
          if (cshape.key === detailKey) {
            if (detail[detailKey] !== null) {
              cshape.content =
                cshape.type === "json_text"
                  ? JSON.parse(detail[detailKey].toString())
                  : detail[detailKey];
            } else {
              cshape.content = "";
            }
          }
        }
      }
    },
  },

  beforeMount() {
    this.getObjects();
  },
  mounted() {
    this.createCanvasInstance();

    this.initializeDraggableElements();
    this.canvas.canvas.addEventListener("contextmenu", (e) => {
      e.preventDefault();
      for (let [index, shape] of this.canvas.shapes.entries()) {
        if (
          this.canvas.isMouseOnShape(
            this.canvas.startX,
            this.canvas.startY,
            shape
          )
        ) {
          this.confirmDelete(index, shape.title);

          return;
        }
      }
    });
    this.canvas.getGrid();
  },
  watch: {
    "canvas.isPreview": async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // await this.getPreviewDetails();
        this.handlePreview();
      }
    },
  },
};
</script>

<style>
canvas {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.15);
  margin-right: 10px;
  /* border: 0.3px solid lightgray; */
  border-radius: 10px;
}

.container {
  width: 100%;
  display: flex;
}

.elements-container {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.15);
  padding: 40px;
  width: 100%;
  border-radius: 10px;
  /* height: 100%; */
  overflow: hidden;
  overflow-y: scroll;
  height: 11.69in;
  /* width: 8.27in; */
}

.element {
  min-height: 50px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 5px;
}
.canvas-container {
  overflow: hidden;
  border-style: dashed;
  border-width: 1px;
  border-color: gray;
}
.floating-editor {
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
  /* border-radius: 10px; */
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
  height: 100%;
}
.floating-editor button {
  height: 25px;
  width: 25px;
  padding: 0px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 4px;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.05);
}
</style>
